/* src/Home.css */
.recipe-container p {
  font-size: 1.2rem;
}

.recipe-container {
  align-items: center;
  text-align: center;
  padding-top: 3rem;
  margin: auto;
  justify-content: center;
  min-height: 90vh;
  height: auto;
}

h2 {
  font-size: 2.5rem;
}

.pantrycontents{
  margin-top: 10rem;
}

.recipe-text {

 
  text-align: center;
  margin: auto;
  
  padding: 2rem;
  width: 60%;
}

.recipe-question-container{
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.recipe-question-container p{
  margin-top: 2rem;
}

.recipe-question-container .pantry-buttons{
  margin-top: 2rem;
}

.no-border {
  border: none;
  text-align: left;
  margin: auto;
  
  padding: 2rem;
  width: 60%;
}

.recipe-box{
  text-align: left;
  border: 1px solid black;
  padding: 2rem;
}

.no-border {
  border: none;
}


