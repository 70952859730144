nav,
.nav-links {
  display: flex;
}

nav {
  justify-content: space-around;
  align-items: center;
  height: 17vh;
  font-family: "Poppins", sans-serif;
}

.nav-links {
  gap: 2rem;
  list-style: none;
  font-size: 1.5rem;
}

a {
  color: black;
  text-decoration: none;
  text-decoration-color: white;
  transition: 0.5s ease;
}

a:hover {
  transition: 0.5s ease;
  padding: 5px 10px;
  color: black; /* Keep the text color */
  text-decoration: none; /* Remove underline */
  text-decoration-color: transparent; /* Remove underline color */
  outline: none;
  background-color: #FCEFB4;
  border-radius: 5px;
}

.logo {
  font-size: 2rem;
}

.logo:hover {
  cursor: default;

}

#hamburger-nav {
    display: none;
  }
  
  .hamburger-menu {
    position: relative;
    display: inline-block;
    
  }
  
  .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 24px;
    width: 30px;
    cursor: pointer;
  }
  
  .hamburger-icon span {
    width: 100%;
    height: 2px;
    background-color: black;
    transition: all 0.3 ease-in-out;
  }
  
  .menu-links {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    width: fit-content;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3 ease-in-out;
  }
  
  .menu-links a {
    display: block;
    padding: 3px;
    text-align: center;
    font-size: 1.2rem;
    color: black;
    text-decoration: none;
    transition: all 0.3 ease-in-out;
  }
  
  .menu-links li {
    list-style: none;
  }
  
  .menu-links.open {
    max-height: 300px;
  }
  
  .hamburger-icon.open span:first-child {
    transform: rotate(45deg) translate(10px, 5px);
  }
  
  .hamburger-icon.open span:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger-icon.open span:last-child {
    transform: rotate(-45deg) translate(10px, -5px);
  }
  
  .hamburger-icon span:first-child {
    transform: none;
  }
  
  .hamburger-icon span:first-child {
    opacity: 1;
  }
  
  .hamburger-icon span:first-child {
    transform: none;
  }
  @media screen and (max-width: 1200px) {
    #desktop-nav {
      display: none;
    }
    #hamburger-nav {
      display: flex;
    }
    #experience,
    .experience-details-container {
      margin-top: 2rem;
    }
    #profile,
    .section-container {
      display: block;
    }
    .arrow {
      display: none;
    }
    section,
    .section-container {
      height: fit-content;
    }
    section {
      margin: 0 5%;
    }
    .section__pic-container {
      width: 275px;
      height: 275px;
      margin: 0 auto 2rem;
    }
    .about-containers {
      margin-top: 0;
    }
  }

  #logo{
    height: 90px;
    
    align-self: flex-start; 
  }


