#fileUploadContainer {
    margin: 20px;
  }
  
 
  #fileInfo {
    margin-top: 10px;
  }
  
  #errorMessage {
    color: red;
  }
  
  /* Style for the file input (hidden) */
  #fileInput {
    display: none;
  }


  .upload-button{
    
    padding: 12px 26px;
    cursor: pointer;
    background-color: #FCEFB4;
    color: black;
    border-radius: 10px;
    border: 0.2px solid black;
    transition: 0.5s ease;
  }

  
.upload-button:hover {

  background-color: #FADA5E;
  color: black;
}
