.text-row {
  display: flex;
  align-items: center;
  margin-top: 7rem;

}


.text-row h2 {
 font-size: 1.5rem;
}


.text-item {
  flex: 1;
  text-align: center;
  padding: 10px; /* Adjust padding as needed */
  height: 150px;
  width: 300px;
}

.text-separator {
  width: 1px;
  height: 250px; /* Set the height of the separator as needed */
  background-color: #000; /* Set the color of the separator as needed */
  margin: 0 10px; /* Set the margin around the separator as needed */
}

@media only screen and (max-width: 768px) {
  .text-row h2 {
    font-size: 1rem;
   }

   .text-item {
    padding: 0px;
    font-size: 0.6rem;
    width: 190px;
    
  }

  .text-separator {
    width: 1px;
    height: 250px; /* Set the height of the separator as needed */
    background-color: #000; /* Set the color of the separator as needed */
    margin: 0 0px; /* Set the margin around the separator as needed */
  }
  

}

  

