
.download-button{
    margin-top: 0.5rem;
    padding: 12px 26px;
    cursor: pointer;
    background-color: #FCEFB4;
    color: black;
    border-radius: 10px;
    border: 0.2px solid black;
    transition: 0.5s ease;
  }


.download-button:hover {

  background-color: #FADA5E;
  color: black;
}
