.page-container {
  display: flex;
  align-items: center;
  flex-direction: column; 
  text-align: center;
  height: 100vh;
  background-color: white;
  overflow-y: hidden;
  overflow-x: hidden;
}



h1{
  margin-bottom: 0rem;
  font-size: 3rem;
}

h4{
  font-weight: 400;

}

.page-container img {
  width: 15rem;
}

.input-container h2 {
  color: #333;
  font-size: 5rem;
  margin-top: 0rem;
  padding: 0;
}


.pantry-container p {
  color: #555;
}

.input-container {
  margin: 0;
  padding: 0;
}

.search-container {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;

}

.banner {
  background-color: black;
  width: 100vw;
}

input {
  margin-right: 0px;
}



.clock-loader-container {
  display: flex;
  margin-top: 0px;
}


.pantry-buttons{

  padding: 12px 20px;
  cursor: pointer;
  background-color: #FCEFB4;
  color: black;
  border-radius: 10px;
  border: 0.2px solid black;
  transition: 0.5s ease;
  flex-shrink: 0
}

.pantry-buttons:hover {

  background-color: #FADA5E;
  color: black;
}
