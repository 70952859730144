.visible {
  position: fixed;
  top: 0;
  right: 20px; /* Initially off-screen */
  width: 460px;
  height: 100%;
  background-color: #333;
  color: #fff;
  padding: 20px;
  transition: right 0.4s ease-out;
  overflow-y: auto;
  height: 90vh;
  margin: 0.8rem;
  border-radius: 50px;
  
}

.none {
  right: -650px; /* Displayed on screen */
  position: fixed;
  top: 0;
  width: 460px;
  height: 100%;
  background-color: #333;
  color: #fff;
  padding: 20px;
  transition: right 0.4s ease-out;
  overflow-y: hidden;
  margin: 0.8rem;
  border-radius: 50px;
  height: 90vh;
}

.visible ul {
  padding: 5px;
}

.item-button {
  color: black;
  background-color: #FCEFB4;
  padding: 10px 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 10px;
  transition: 558ms;
  transform: translateY(0);
  cursor: pointer;
  margin: 4px;
  transition: 0.2s ease;
}


.item-button:hover {
  background-color: red;
  color: white;
}

.pantryText p {
  background-color: white;
}

.close-button {
  display: flex;
  
  color: black;
  border-radius: 10px;
  background-color: white;
  font-size: 1rem;
  padding: 8px 10px;
  border: none;
  margin-top: 1rem;
  margin-right: 80%;
  transition: 0.4s ease;
}

.close-button:hover {
  background-color: #333;
  color: white;
  box-shadow: none;
}


.pantry-title{
  font-size: 1.3rem;
}

.closeBtn{
  margin-top: 6rem;
}


@media only screen and (max-width: 768px) {
  .visible {
    position: fixed;
    top: 0;
    right: 20px; /* Initially off-screen */
    width: 280px;
    height: 100%;
    background-color: #333;
    color: #fff;
    padding: 20px;
    transition: right 0.4s ease-out;
    overflow-y: auto;
    height: 90vh;
    margin: 1rem;
    border-radius: 50px;
  }
  
  .none {
    right: -370px; /* Displayed on screen */
    position: fixed;
    top: 0;
    width: 280px;
    height: 100%;
    background-color: #333;
    color: #fff;
    padding: 20px;
    transition: right 0.4s ease-out;
    overflow-y: hidden;
    margin: 1rem;
    border-radius: 50px;
    height: 90vh;
  }

  
.close-button {
  margin-right: 70%;
}


}