/* PopUp.css */
.pop-up {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  width: 730px;
  height: 300px;
  border: 2px solid #333; /* Add a border */
  background: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
}

select {
  border-radius: 8px; /* You can adjust the value to control the amount of rounding */
  padding: 6px; /* Add some padding for better visual appearance */
}

.pop-title {
  font-size: 2rem;
  padding-bottom: 1.5rem;
}

.pop-up.show {
  opacity: 0.99;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 25px;
}

p {
  /* style for each question */
  margin: 10px 0;
}

select {
  /* style for the dropdown */
  margin-left: 10px;
  width: 140px; /* adjust the width as needed */
}

.pop-up button {
  margin-right: 2rem;
  margin-top: 3.5rem;
  max-height: 45px;
  height: 45px;
  font-weight: 0;
  font-size: 15px;
  color: #fff;
  background-color: #ff8b00;
  padding: 9px 26px;
  border: none;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
  border-radius: 15px;
  transition: 691ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.pop-up button:hover {
  transition: 691ms;

  background-color: white;
  color: #ff8b00;
}
