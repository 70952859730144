.searchInputs {

  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin-right: 1rem;
}

input {
  padding-left: 20px;
}

.search input {
  background-color: white;
  border: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-size: 18px;

  height: 45px;
  width: 350px;
}

.search {
  display: flex;
  flex-direction: column;
  overflow: visible;
  align-items: center;
  width: 100%;
  height: 45px;

  
}

.searchIcon {
  height: 47px;
  width: 50px;
  background-color: white;
  display: grid;
  place-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 35px;
}

.dataResult {
  margin-top: 3rem;
  margin-right: 1rem;
  width: 425px;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 5px;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}

a:hover {
  background-color: lightgrey;
}

#clearBtn {
  cursor: pointer;
}

.li {
  color: black;
}


@media only screen and (max-width: 768px) {
  .search input {
    background-color: white;
    border: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    font-size: 12px;
  
    height: 45px;
    width: 200px;
  }
  
  .dataResult {
    margin-top: 3rem;
    margin-right: 1rem;
    width: 270px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: absolute;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 5px;
  }

}