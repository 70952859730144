.home-wrapper{
    height: auto;
    background: white;
    font-family: "Poppins", sans-serif;
    padding-bottom: 20rem;
    align-items: center; 
}



#mainpage {
 
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    gap: 5rem;
    flex-wrap: wrap;
    align-items: center; 
    
  }
  
  .section__pic-container {
    
    display: flex;
    height: 320px;
    width: 320px;
    

  }

  video{
    margin-top: 1rem;
    border-radius: 25px;
  }
  
  .section__text {
    margin-left: 14rem;
    align-self: center;
    text-align: left;
    max-width: 45%;
    
  }
  
  .section__text p {
    font-weight: 600;
  }
  
  .section__text__p1 {
    text-align: left;
  }
  
  .section__text__p2 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }
  
  .title {
    font-size: 3rem;
    text-align: left;
  }
  
  #socials-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 1rem;
  }

  @media only screen and (max-width: 1200px) {
   
    #mainpage{
        margin-top: 2rem;
    }
  
    .section__text {
      margin-left: 0;
      max-width: 90%;
      margin-top: 0rem; /* Adjust spacing between video and text on mobile */
      text-align: center;
    }


    h1{
        margin-top: 0;
    }

    video{
        height: 200px;
        border-radius: 5px;
      

    }

    .section__pic-container {
    
        display: flex;
        height: unset;
        width: unset;
        
    
      }

   


  }